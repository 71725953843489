import React, { useState, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ADD_FAMILY_MEMBER = gql`
  mutation CreateFamilyMember($data: FamilyMemberInput!) {
    createFamilyMember(data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const UPDATE_FAMILY_MEMBER = gql`
  mutation UpdateFamilyMember($id: ID!, $data: FamilyMemberInput!) {
    updateFamilyMember(id: $id, data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const GET_FAMILY_MEMBERS = gql`
  query GetFamilyMembers {
    familyMembers {
      data {
        id
        attributes {
          name
          date_of_birth
          gender
          is_alive
          date_of_death
          place_of_birth
          nationality
          email
          mobile
          address
          county
          clan
          mothers_clan
          village
          branch
          posterity
          thigh
          occupation
          biographical_information
          parent {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

function FamilyMemberForm({
  memberId = null,
  parentInfo = null,
  onSuccess,
  closeModal,
}) {
  const [formData, setFormData] = useState({
    name: "",
    dateOfBirth: "",
    gender: "",
    isAlive: true,
    dateOfDeath: "",
    placeOfBirth: "",
    nationality: "",
    email: "",
    mobile: "",
    address: "",
    county: "",
    clan: "",
    mothersClan: "",
    village: "",
    branch: "",
    posterity: "",
    thigh: "",
    occupation: "",
    biographicalInformation: "",
    parentId: null,
  });

  const [addFamilyMember] = useMutation(ADD_FAMILY_MEMBER, {
    onCompleted: handleSuccess,
    onError: handleError,
    refetchQueries: [{ query: GET_FAMILY_MEMBERS }],
  });

  const [updateFamilyMember] = useMutation(UPDATE_FAMILY_MEMBER, {
    onCompleted: handleSuccess,
    onError: handleError,
    refetchQueries: [{ query: GET_FAMILY_MEMBERS }],
  });

  const { data: membersData } = useQuery(GET_FAMILY_MEMBERS);

  useEffect(() => {
    if (memberId && membersData) {
      const member = membersData.familyMembers.data.find(
        (m) => m.id === memberId
      );
      if (member) {
        setFormData({
          name: member.attributes.name || "",
          dateOfBirth: member.attributes.date_of_birth || "",
          gender: member.attributes.gender || "",
          isAlive: member.attributes.is_alive,
          dateOfDeath: member.attributes.date_of_death || "",
          placeOfBirth: member.attributes.place_of_birth || "",
          nationality: member.attributes.nationality || "",
          email: member.attributes.email || "",
          mobile: member.attributes.mobile || "",
          address: member.attributes.address || "",
          county: member.attributes.county || "",
          clan: member.attributes.clan || "",
          mothersClan: member.attributes.mothers_clan || "",
          village: member.attributes.village || "",
          branch: member.attributes.branch || "",
          posterity: member.attributes.posterity || "",
          thigh: member.attributes.thigh || "",
          occupation: member.attributes.occupation || "",
          biographicalInformation:
            member.attributes.biographical_information || "",
          parentId: member.attributes.parent?.data?.id || "",
        });
      }
    } else if (parentInfo && parentInfo.name && membersData) {
      const parent = membersData.familyMembers.data.find(
        (member) => member.attributes.name === parentInfo.name
      );
      if (parent) setFormData((prev) => ({ ...prev, parentId: parent.id }));
    }
  }, [memberId, parentInfo, membersData]);

  function handleInputChange(e) {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  function handleSuccess() {
    if (onSuccess) onSuccess();
    resetForm();
    toast.success(
      `Family member ${memberId ? "updated" : "added"} successfully!`,
      { autoClose: 3000 }
    );
    if (closeModal) closeModal();
  }

  function handleError(err) {
    let errorMessage = "An unknown error occurred";
    if (err.graphQLErrors) {
      errorMessage = err.graphQLErrors.map(({ message }) => message).join(", ");
    } else if (err.networkError) {
      errorMessage = "Network error: " + err.networkError.message;
    }
    toast.error(
      `Failed to ${memberId ? "update" : "add"} family member: ${errorMessage}`,
      {
        autoClose: 5000,
      }
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const mutationVariables = {
      data: {
        name: formData.name,
        date_of_birth: formData.dateOfBirth || null,
        gender: formData.gender,
        is_alive: formData.isAlive,
        date_of_death: !formData.isAlive ? formData.dateOfDeath : null,
        place_of_birth: formData.placeOfBirth || null,
        nationality: formData.nationality || null,
        email: formData.email || null,
        mobile: formData.mobile || null,
        address: formData.address || null,
        county: formData.county || null,
        clan: formData.clan || null,
        mothers_clan: formData.mothersClan || null,
        village: formData.village || null,
        branch: formData.branch || null,
        posterity: formData.posterity || null,
        thigh: formData.thigh || null,
        occupation: formData.occupation || null,
        biographical_information: formData.biographicalInformation || null,
        parent: formData.parentId || null,
      },
    };

    Object.keys(mutationVariables.data).forEach(
      (key) =>
        mutationVariables.data[key] === null &&
        delete mutationVariables.data[key]
    );

    // Handle success...
    try {
      let result;
      if (memberId) {
        result = await updateFamilyMember({
          variables: { id: memberId, ...mutationVariables },
        });
      } else {
        result = await addFamilyMember({ variables: mutationVariables });
      }
    } catch (error) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(
          ({ message, locations, path, extensions }) => {
            console.error(
              `GraphQL error: ${message}`,
              `Location: ${JSON.stringify(locations)}`,
              `Path: ${path}`,
              `Extensions: ${JSON.stringify(extensions)}`
            );
          }
        );
      }
      if (error.networkError) {
        console.error("Network error:", error.networkError);
      }
    }
  }

  function resetForm() {
    setFormData({
      name: "",
      dateOfBirth: "",
      gender: "",
      isAlive: true,
      dateOfDeath: "",
      placeOfBirth: "",
      nationality: "",
      email: "",
      mobile: "",
      address: "",
      county: "",
      clan: "",
      mothersClan: "",
      village: "",
      branch: "",
      posterity: "",
      thigh: "",
      occupation: "",
      biographicalInformation: "",
      parentId: null,
    });
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">
        {memberId ? "Edit Family Member" : "Add Family Member"}
      </h2>
      <form onSubmit={handleSubmit} className="w-500 space-y-4">
        <div className="form-group">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name || ""}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth || ""}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="gender"
            className="block text-sm font-medium text-gray-700"
          >
            Gender
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          >
            <option value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label
            htmlFor="isAlive"
            className="block text-sm font-medium text-gray-700"
          >
            Is Alive
          </label>
          <input
            type="checkbox"
            id="isAlive"
            name="isAlive"
            checked={formData.isAlive}
            onChange={handleInputChange}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded px-2 py-4"
          />
        </div>
        {!formData.isAlive && (
          <div className="form-group">
            <label
              htmlFor="dateOfDeath"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Death
            </label>
            <input
              type="date"
              id="dateOfDeath"
              name="dateOfDeath"
              value={formData.dateOfDeath || ""}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
            />
          </div>
        )}
        <div className="form-group">
          <label
            htmlFor="placeOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Place Of Birth
          </label>
          <input
            type="text"
            id="placeOfBirth"
            name="placeOfBirth"
            value={formData.placeOfBirth || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="nationality"
            className="block text-sm font-medium text-gray-700"
          >
            Nationality
          </label>
          <input
            type="text"
            id="nationality"
            name="nationality"
            value={formData.nationality || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email Address
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile Number
          </label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700"
          >
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="county"
            className="block text-sm font-medium text-gray-700"
          >
            County
          </label>
          <input
            type="text"
            id="county"
            name="county"
            value={formData.county || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="clan"
            className="block text-sm font-medium text-gray-700"
          >
            Clan
          </label>
          <input
            type="text"
            id="clan"
            name="clan"
            value={formData.clan || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="mothersClan"
            className="block text-sm font-medium text-gray-700"
          >
            Mother's Clan
          </label>
          <input
            type="text"
            id="mothersClan"
            name="mothersClan"
            value={formData.mothersClan || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="village"
            className="block text-sm font-medium text-gray-700"
          >
            Village
          </label>
          <input
            type="text"
            id="village"
            name="village"
            value={formData.village || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="branch"
            className="block text-sm font-medium text-gray-700"
          >
            Branch
          </label>
          <input
            type="text"
            id="branch"
            name="branch"
            value={formData.branch || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="posterity"
            className="block text-sm font-medium text-gray-700"
          >
            Posterity
          </label>
          <input
            type="text"
            id="posterity"
            name="posterity"
            value={formData.posterity || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="thigh"
            className="block text-sm font-medium text-gray-700"
          >
            Thigh
          </label>
          <input
            type="text"
            id="thigh"
            name="thigh"
            value={formData.thigh || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="occupation"
            className="block text-sm font-medium text-gray-700"
          >
            Occupation
          </label>
          <input
            type="text"
            id="occupation"
            name="occupation"
            value={formData.occupation || ""}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>

        <div className="form-group">
          <label
            htmlFor="parent"
            className="block text-sm font-medium text-gray-700"
          >
            Parent
          </label>
          <input
            type="text"
            id="parent"
            value={parentInfo ? parentInfo.name : ""}
            readOnly
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-2 py-4"
          />
        </div>
        <div className="form-group text-center">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {memberId ? "Update Member" : "Add Member"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default FamilyMemberForm;
